@use '../util/' as *;


.cards {
    display: flex;
    justify-content: space-between;
}

.card {
    width: 25%;
    height: rem(170);
    background-color: var(--card-grey);
    padding: rem(30);
    margin: 0 rem(10);
    border-radius: rem(20);
    display: flex;
    flex-direction: column;

}

.card div{
    display: flex;
    width: rem(40);
    height: rem(40);
    border-radius: 50%;
}

.cards img{
    display: inline-block;
    padding: rem(7);
    margin-bottom: rem(-3);
}

.card.graphic-design{
    margin-left: 0;
}
.card.seo-dev{
    margin-right: 0;
}

.card.graphic-design div {
    background-color: var(--orange);
}

.card.web-design div {
    background-color: var(--red);   
}

.card.web-dev div {
    background-color: var(--lightblue);
}

.card.seo-dev div {
    background-color: var(--green);
}

.card h3{
    margin-top: rem(15);
}

.card.graphic-design h3{
    color: var(--orange)
}

.card.web-design h3{
    color: var(--red);
}

.card.web-dev h3{
    color: var(--lightblue);
}

.card.seo-dev h3{
    color: var(--green);
}



.card p{
    color: #fff;
}