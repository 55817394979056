@use '../util/' as *;

.header, .projects-header .logo-nav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: rem(30);
}

.site-logo{
    width: rem(35);
}

.site-logo svg {
    scale: 0.8;
}

.site-logo svg path{
    fill: var(--white);
}

.right-column{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: rem(50);

    @media (max-width: 767px){
        gap: rem(20);
    }
}

.contact{
    color: var(--white);
    font-family: var(--font-space);
    font-weight: 700;
}

.contact svg{
    width: rem(12);
    margin-left: rem(5);
    transform: rotate(-45deg);
    margin-bottom: rem(-3);
}

.contact svg path{
    fill: var(--white);
}

.contact:hover{
    color: var(--blue); 
}
.contact:hover svg{
    transform: rotate(0deg);
}


.contact:hover svg path{
    fill: var(--blue) !important;
}

.light-dark-container button{
    background: none;
    border: none;
}

.light-dark-container{
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: rem(35);
    height: rem(35);
    overflow: hidden;
}

.mode-btn svg path{
    fill: var(--white);
}

.light-mode-btn.active{
    margin-top: rem(10);
}

.light-mode-btn{
    scale: 1.2;
    margin-top: rem(-25);
}

.dark-mode-btn{
    scale: 0.6;
}

.hamburger-icon{
    display: block;
    cursor: pointer;
    margin-right: 0;
    width: rem(30);
    overflow: hidden;
    margin-top: rem(-5);
}

.hamburger-icon span{
    display: block;
    height: rem(2);
    margin-top: rem(8);
    background-color: var(--white);
    width: 100%;
    border-radius: 15px;
}

.open-close-modal-btn.show span:first-child{
    margin-bottom: rem(-10);
}

.open-close-modal-btn.show:hover span:first-child{
    margin-left: 0;
}

.hamburger-icon .bar-2{
   margin-left: rem(10);
}

.hamburger-icon:hover .bar-1{
    margin-left: rem(10);
}
.hamburger-icon:hover .bar-2{
    margin-left: rem(0);
}

.home-section{
    width: 100%;
    margin: 0 auto;
    margin: rem(75) 0;
}

.back-btn{
    display: flex;
    align-items: center;
    position: absolute;
    top: 15%;

    @media (max-width: 767) {
        top: 18%;
    }
}

.back-btn button{
    border: none;
    font-weight: 700;
}

.back-btn svg{
    margin: 0 5px -3px;

    scale: 0.8;
    rotate: 180deg;
}

.back-btn svg path{
    fill: var(--white);
}

.back-btn:hover a, .back-btn:hover svg path{
    color: var(--blue);
    fill: var(--blue);
}

.main-title{
    text-align: center;
}

h1{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: var(--white);
    margin: 0;
}









// Modals
.about-me-container{
    opacity: 0;
    transform: translateY(-50%);
    position: fixed;;
    background-color: var(--black);
    inset: 0;
    padding: rem(100);
    color: var(--white);
    z-index: 0;
    transition: all 300ms ease-in-out;
    pointer-events: none;
    margin: auto;
    overflow: scroll;

    @media (max-width: 1024px){
        padding: rem(50);
    }

    @media (max-width: 767px){
        padding: rem(50) rem(30) 0;
    }
}

.about-me-container.show{
    opacity: 1;
    transform: translateY(0%);
    pointer-events: auto;
}


.about-me-container .modal{
    display: flex;
    gap: rem(50);
    margin-bottom: rem(50);

    @media (max-width: 1024px){
        flex-direction: column;
        gap: rem(10);
    }
}

.about-me-container .modal h5{
    font-size: rem(20);

    @media (max-width: 1366px){
        font-size: rem(15);
    }
}

.modal .contact h3{
    color: var(--blue);
    margin: 0;
}

.about-me-container h1{
    font-weight: 700;
}

.about-me-container .me-text-container{
    display: flex;
    flex-direction: column;
    row-gap: rem(7);
}

.technologies-gallery{
    margin-top: rem(50);
    display: grid;
    gap: rem(30);
    align-items: center;
    grid-template-columns: repeat(4, 1fr);
    
    @media (max-width: 767px) {
        margin-top: rem(30);
        gap: rem(20);
        grid-template-columns: repeat(3, 1fr);
    }
}

.technologies-gallery img{
    scale: 0.8;

    @media (max-width: 767px) {
        scale: 1;
    }
}

.open-close-modal-btn{
    z-index: 9;
}