@use '../util/' as *;

.footer .contact{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: rem(150);

    @media (max-width: 1070px) {
        flex-direction: column;
        align-items: start;
        margin-bottom: rem(30);
        margin-top: rem(150);
        gap: rem(10);
    }
    
}

.footer span{
    display: flex;
    flex-direction: column;
}

.footer span a{
    font: 300 rem(18) var(--font-space);
} 

.footer a{
    margin-top: rem(10);
}

.footer h1, .footer h2, .footer a{
    color: var(--white);
}

.footer h1{
    font-size: rem(150);
    margin: 0;

    @media (max-width: 767px) {
        font-size: rem(75);
    }
}

.footer .copyright{
    border-top: 1px solid var(--light-grey);
    display: flex;
    justify-content: space-between;
}

.footer .copyright span{
    display: flex;
    flex-direction: row;
}
.footer .copyright span a:not(:last-child):after{
    content: '.';
    margin: 0 rem(5);
}

.footer .copyright p, .footer .copyright span a{
    color: var(--light-grey);
    font: 300 rem(14) var(--font-space);
}