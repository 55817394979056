:root {
  --font-sora: "Sora", sans-serif;
  --font-space: "Space Grotesk", sans-serif;
}

h1, h2, h3 {
  margin-top: 0;
  color: var(--red);
}

a, a:visited, a:active {
  text-decoration: none;
  font-size: 0.75rem;
}

h1 {
  font-size: 4.375rem;
  font-weight: 200;
}
@media (max-width: 767px) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: 1.875rem;
}

h3 {
  font-size: 1.375rem;
}

h5 {
  font-family: var(--font-space);
  font-size: 0.9375rem;
  font-weight: 200;
}

p {
  font: 300 0.75rem var(--font-space);
}

:root {
  --black: #121314;
  --card-grey: #202124;
  --light-grey: #848484;
  --blue: #4267F7;
  --dark-blue: #2f4fcf;
  --orange: #F7BF7D;
  --red: #E56271;
  --lightblue: #6FACFA;
  --green: #73DDBC;
  --white: #ffffff;
}

.light-mode {
  --black: #f7f8f9;
  --card-grey: #202124;
  --light-grey: #848484;
  --blue: #4267F7;
  --dark-blue: #2f4fcf;
  --orange: #F7BF7D;
  --red: #E56271;
  --lightblue: #6FACFA;
  --green: #73DDBC;
  --white: #121314;
  transition: all 300ms ease;
}

html {
  font-size: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  width: 100%;
  overflow-x: hidden;
}

*, *::before *::after {
  box-sizing: inherit;
}

:root {
  --btn-padding: 1.25rem 2.188rem;
  --border-radius: 3.125rem;
}

body {
  margin: 0;
  padding: 0 3.125rem;
  font-family: var(--font-sora);
  background-color: var(--black);
  box-sizing: inherit;
  width: 100%;
  overflow-x: hidden;
}
@media (max-width: 767px) {
  body {
    padding: 0 1.25rem;
  }
}

a, button, a * {
  cursor: pointer;
  color: var(--white);
  background: none;
  border: none;
}

a, a *, h2 {
  transition: all 300ms ease;
}

figure, h2 {
  margin: 0 !important;
}

h1 {
  color: var(--white);
}

.projects-container {
  padding: 0 4.6875rem;
}
@media (max-width: 1300px) {
  .projects-container {
    padding: 0;
  }
}

.projects {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  row-gap: 0rem;
  gap: 4.6875rem;
  padding: 0;
}
@media (max-width: 1024px) {
  .projects {
    grid-template-columns: 1fr;
    gap: 3.125rem;
  }
}

.projects li:nth-child(odd) {
  margin-top: 6.25rem;
}
@media (max-width: 767px) {
  .projects li:nth-child(odd) {
    margin-top: 0;
  }
}

.projects-grid {
  display: none;
}

.projects li {
  display: flex;
  flex-direction: column;
  height: 90%;
}

.projects li figure {
  overflow: hidden;
  height: 100%;
  border-radius: 0.9375rem;
}

.projects li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  scale: 1.1;
}

.projects li:hover img {
  scale: 1.15;
}

.projects li .project-data {
  margin-top: 1.5625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5625rem;
}

.project-data h2, .project-data p {
  color: var(--white);
}

.projects li .project-data .visit-arrow::before {
  content: "Visit";
  color: var(--black);
  font: 700 0.625rem var(--font-sora);
  text-transform: uppercase;
  background-color: var(--white);
  padding: 0.1875rem 0.75rem 0.125rem 0.3125rem;
  position: absolute;
  clip-path: polygon(0% 0%, 0 0, 0 54%, 0 100%, 0% 100%);
  margin-top: 1.0625rem;
  margin-left: -3.125rem;
  transition: all 200ms ease;
  opacity: 0;
}

.projects li:hover .project-data .visit-arrow:before {
  opacity: 1;
  clip-path: polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%);
}

.project .project-data svg {
  background-color: var(--blue);
  padding: 0.9375rem;
  border-radius: 3.125rem;
  transform: rotate(-45deg);
}

.projects li .visit-arrow:hover svg {
  transform: rotate(0deg);
}

.projects li:hover h2 {
  color: var(--blue);
}

.toggle {
  width: 1.25rem;
}

.cards {
  display: flex;
  justify-content: space-between;
}

.card {
  width: 25%;
  height: 10.625rem;
  background-color: var(--card-grey);
  padding: 1.875rem;
  margin: 0 0.625rem;
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
}

.card div {
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}

.cards img {
  display: inline-block;
  padding: 0.4375rem;
  margin-bottom: -0.1875rem;
}

.card.graphic-design {
  margin-left: 0;
}

.card.seo-dev {
  margin-right: 0;
}

.card.graphic-design div {
  background-color: var(--orange);
}

.card.web-design div {
  background-color: var(--red);
}

.card.web-dev div {
  background-color: var(--lightblue);
}

.card.seo-dev div {
  background-color: var(--green);
}

.card h3 {
  margin-top: 0.9375rem;
}

.card.graphic-design h3 {
  color: var(--orange);
}

.card.web-design h3 {
  color: var(--red);
}

.card.web-dev h3 {
  color: var(--lightblue);
}

.card.seo-dev h3 {
  color: var(--green);
}

.card p {
  color: #fff;
}

.header, .projects-header .logo-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.875rem;
}

.site-logo {
  width: 2.1875rem;
}

.site-logo svg {
  scale: 0.8;
}

.site-logo svg path {
  fill: var(--white);
}

.right-column {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 3.125rem;
}
@media (max-width: 767px) {
  .right-column {
    gap: 1.25rem;
  }
}

.contact {
  color: var(--white);
  font-family: var(--font-space);
  font-weight: 700;
}

.contact svg {
  width: 0.75rem;
  margin-left: 0.3125rem;
  transform: rotate(-45deg);
  margin-bottom: -0.1875rem;
}

.contact svg path {
  fill: var(--white);
}

.contact:hover {
  color: var(--blue);
}

.contact:hover svg {
  transform: rotate(0deg);
}

.contact:hover svg path {
  fill: var(--blue) !important;
}

.light-dark-container button {
  background: none;
  border: none;
}

.light-dark-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 2.1875rem;
  height: 2.1875rem;
  overflow: hidden;
}

.mode-btn svg path {
  fill: var(--white);
}

.light-mode-btn.active {
  margin-top: 0.625rem;
}

.light-mode-btn {
  scale: 1.2;
  margin-top: -1.5625rem;
}

.dark-mode-btn {
  scale: 0.6;
}

.hamburger-icon {
  display: block;
  cursor: pointer;
  margin-right: 0;
  width: 1.875rem;
  overflow: hidden;
  margin-top: -0.3125rem;
}

.hamburger-icon span {
  display: block;
  height: 0.125rem;
  margin-top: 0.5rem;
  background-color: var(--white);
  width: 100%;
  border-radius: 15px;
}

.open-close-modal-btn.show span:first-child {
  margin-bottom: -0.625rem;
}

.open-close-modal-btn.show:hover span:first-child {
  margin-left: 0;
}

.hamburger-icon .bar-2 {
  margin-left: 0.625rem;
}

.hamburger-icon:hover .bar-1 {
  margin-left: 0.625rem;
}

.hamburger-icon:hover .bar-2 {
  margin-left: 0rem;
}

.home-section {
  width: 100%;
  margin: 0 auto;
  margin: 4.6875rem 0;
}

.back-btn {
  display: flex;
  align-items: center;
  position: absolute;
  top: 15%;
}
@media (max-width: 767) {
  .back-btn {
    top: 18%;
  }
}

.back-btn button {
  border: none;
  font-weight: 700;
}

.back-btn svg {
  margin: 0 5px -3px;
  scale: 0.8;
  rotate: 180deg;
}

.back-btn svg path {
  fill: var(--white);
}

.back-btn:hover a, .back-btn:hover svg path {
  color: var(--blue);
  fill: var(--blue);
}

.main-title {
  text-align: center;
}

h1 {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: var(--white);
  margin: 0;
}

.about-me-container {
  opacity: 0;
  transform: translateY(-50%);
  position: fixed;
  background-color: var(--black);
  inset: 0;
  padding: 6.25rem;
  color: var(--white);
  z-index: 0;
  transition: all 300ms ease-in-out;
  pointer-events: none;
  margin: auto;
  overflow: scroll;
}
@media (max-width: 1024px) {
  .about-me-container {
    padding: 3.125rem;
  }
}
@media (max-width: 767px) {
  .about-me-container {
    padding: 3.125rem 1.875rem 0;
  }
}

.about-me-container.show {
  opacity: 1;
  transform: translateY(0%);
  pointer-events: auto;
}

.about-me-container .modal {
  display: flex;
  gap: 3.125rem;
  margin-bottom: 3.125rem;
}
@media (max-width: 1024px) {
  .about-me-container .modal {
    flex-direction: column;
    gap: 0.625rem;
  }
}

.about-me-container .modal h5 {
  font-size: 1.25rem;
}
@media (max-width: 1366px) {
  .about-me-container .modal h5 {
    font-size: 0.9375rem;
  }
}

.modal .contact h3 {
  color: var(--blue);
  margin: 0;
}

.about-me-container h1 {
  font-weight: 700;
}

.about-me-container .me-text-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.4375rem;
}

.technologies-gallery {
  margin-top: 3.125rem;
  display: grid;
  gap: 1.875rem;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
}
@media (max-width: 767px) {
  .technologies-gallery {
    margin-top: 1.875rem;
    gap: 1.25rem;
    grid-template-columns: repeat(3, 1fr);
  }
}

.technologies-gallery img {
  scale: 0.8;
}
@media (max-width: 767px) {
  .technologies-gallery img {
    scale: 1;
  }
}

.open-close-modal-btn {
  z-index: 9;
}

.footer .contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 9.375rem;
}
@media (max-width: 1070px) {
  .footer .contact {
    flex-direction: column;
    align-items: start;
    margin-bottom: 1.875rem;
    margin-top: 9.375rem;
    gap: 0.625rem;
  }
}

.footer span {
  display: flex;
  flex-direction: column;
}

.footer span a {
  font: 300 1.125rem var(--font-space);
}

.footer a {
  margin-top: 0.625rem;
}

.footer h1, .footer h2, .footer a {
  color: var(--white);
}

.footer h1 {
  font-size: 9.375rem;
  margin: 0;
}
@media (max-width: 767px) {
  .footer h1 {
    font-size: 4.6875rem;
  }
}

.footer .copyright {
  border-top: 1px solid var(--light-grey);
  display: flex;
  justify-content: space-between;
}

.footer .copyright span {
  display: flex;
  flex-direction: row;
}

.footer .copyright span a:not(:last-child):after {
  content: ".";
  margin: 0 0.3125rem;
}

.footer .copyright p, .footer .copyright span a {
  color: var(--light-grey);
  font: 300 0.875rem var(--font-space);
}

.project-page main {
  margin: 6.25rem 0;
}

.project-page hr {
  margin: 3.125rem 0;
}

.back-to-home {
  font: 700 0.875rem var(--font-space);
  display: flex;
  margin-bottom: 3.125rem;
  gap: 0.625rem;
  z-index: 999;
}

.back-to-home svg {
  rotate: -180deg;
  scale: 0.9;
  margin-top: 0.125rem;
}

.back-to-home:hover {
  color: var(--blue);
  gap: 0.9375rem;
}

.back-to-home:hover svg path {
  fill: var(--blue);
}

.single-project-title {
  margin-bottom: 1.25rem;
}

.single-project-title h1 {
  overflow: hidden;
}

.single-project-title h1 span {
  overflow: hidden;
}

.single-project-title h1:nth-child(2) {
  margin-top: 1.875rem;
}

.project-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 9.375rem;
  color: var(--white);
}
@media (max-width: 1210px) {
  .project-info {
    flex-direction: column;
    gap: 3.125rem;
  }
}

.project-info-column {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

@media (max-width: 1210px) {
  .project-info-column, .project-client-columnn, .project-info-row {
    width: 100%;
    align-items: start;
    justify-content: space-between;
  }
}

.project-client-columnn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-info-row {
  display: flex;
}

.project-info-row:not(:last-child) {
  display: flex;
}

.project-info-row:last-child {
  display: flex;
  justify-content: end;
}
@media (max-width: 1210px) {
  .project-info-row:last-child {
    width: 100%;
    justify-content: start;
  }
}

.project-info-row:not(:last-child) {
  padding: 0.625rem 0rem;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--white);
}

.live-site-button {
  align-self: flex-start;
  padding: 1.25rem 0;
}
@media (max-width: 767px) {
  .live-site-button {
    margin-top: 1.25rem;
  }
}

.live-site-button a {
  background-color: var(--blue);
  border-radius: var(--border-radius);
}

.live-site-button svg {
  rotate: -45deg;
  scale: 0.9;
  margin-left: 10px;
  margin-bottom: -3px;
}

.live-site-button a:hover {
  background-color: var(--dark-blue);
}

.live-site-button:hover svg {
  rotate: 0deg;
}

.project-info a {
  padding: var(--btn-padding);
  color: white;
  font: 700 1.125rem var(--font-space);
}

.project-info-row p {
  font: 300 1.125rem var(--font-space);
}

.project-info-row p:first-child {
  font: 700 0.875rem var(--font-sora);
  text-transform: uppercase;
}

.project-info-row ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0;
  padding: 0;
  margin-top: 1.875rem;
}

.project-info-row ul li {
  border: 1px solid var(--white);
  padding: var(--btn-padding);
  border-radius: var(--border-radius);
  font: 300 0.875rem var(--font-space);
}

.project-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3.125rem;
}
@media (max-width: 1024px) {
  .project-gallery {
    grid-template-columns: 1fr;
  }
}

.project-gallery img:first-child {
  grid-column: 1/-2;
  height: 100%;
}

.project-gallery img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.project-gallery .project-img-carousel {
  grid-column: 1/-1;
  height: 100%;
}

.project-img-carousel {
  display: grid;
  width: 100%;
  gap: 3.125rem;
  margin-bottom: 3.125rem;
}

.project-img-carousel img {
  width: 100%;
}