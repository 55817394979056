@use '../util/' as *;

h1, h2, h3{
    margin-top: 0;
    color: var(--red);
}

a, a:visited, a:active{
    text-decoration: none;
    font-size: rem(12);
}

h1{
    font-size: rem(70);
    font-weight: 200;

    @media (max-width: 767px){
        font-size: rem(40);
    }
}

h2 {
    font-size: rem(30);
}

h3{
    font-size: rem(22);
}

h5{
    font-family: var(--font-space);
    font-size: rem(15);
    font-weight: 200; 
}

p{
    font: 300 rem(12) var(--font-space);
}