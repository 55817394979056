:root{
    --black: #121314;
    --card-grey: #202124;
    --light-grey: #848484;
    --blue: #4267F7;
    --dark-blue: #2f4fcf;
    --orange: #F7BF7D;
    --red: #E56271;
    --lightblue: #6FACFA;
    --green: #73DDBC;
    --white: #ffffff;
}

.light-mode{
    --black: #f7f8f9;
    --card-grey: #202124;
    --light-grey: #848484;
    --blue: #4267F7;
    --dark-blue: #2f4fcf;
    --orange: #F7BF7D;
    --red: #E56271;
    --lightblue: #6FACFA;
    --green: #73DDBC;
    --white: #121314;
    transition: all 300ms ease;
}