@use '../util/' as *;

.project-page main{
    margin: rem(100) 0;
}

.project-page hr{
    margin: rem(50) 0;
}

.back-to-home{
    font: 700 rem(14) var(--font-space);
    display: flex;
    margin-bottom: rem(50);
    gap: rem(10);
    z-index: 999;
}

.back-to-home svg{
    rotate: -180deg;
    scale: 0.9;
    margin-top: rem(2);
}

.back-to-home:hover{
    color: var(--blue);
    gap: rem(15);
}

.back-to-home:hover svg path{
    fill: var(--blue);
}

.single-project-title{
    margin-bottom: rem(20);
}

.single-project-title h1{
    overflow: hidden;
}

.single-project-title h1 span{
    overflow: hidden;
}

.single-project-title h1:nth-child(2){
    margin-top: rem(30);
}

.project-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: rem(150);
    color: var(--white);

    @media (max-width: 1210px){
        flex-direction: column;
        gap: rem(50);
    }
}

.project-info-column{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.project-info-column, .project-client-columnn, .project-info-row{
    @media (max-width: 1210px){
        width: 100%;
        align-items: start;
        justify-content: space-between;
    }

}

.project-client-columnn{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.project-info-row{
    display: flex;
}

.project-info-row:not(:last-child){
    display: flex;
}

.project-info-row:last-child{
    display: flex;
    justify-content: end;

    @media (max-width: 1210px){
        width: 100%;
        justify-content: start;
    }
}

.project-info-row:not(:last-child){
    padding: rem(10) rem(0);
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--white);
}

.live-site-button{
    align-self: flex-start;
    padding: 1.25rem 0;

    @media (max-width: 767px){
        margin-top: rem(20);
    }
}

.live-site-button a{
    background-color: var(--blue);
    border-radius: var(--border-radius);
}

.live-site-button svg{
    rotate: -45deg;
    scale: 0.9;
    margin-left: 10px;
    margin-bottom: -3px;
}

.live-site-button a:hover{
    background-color: var(--dark-blue);
}

.live-site-button:hover svg{
    rotate: 0deg;
}

.project-info a{
    padding: var(--btn-padding);
    color: white;
    font: 700 rem(18) var(--font-space);
}


.project-info-row p{
    font: 300 rem(18) var(--font-space);
}

.project-info-row p:first-child{
    font: 700 rem(14) var(--font-sora);
    text-transform: uppercase;
}

.project-info-row ul{
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 0;
    padding: 0;
    margin-top: rem(30);
}

.project-info-row ul li{
    border: 1px solid var(--white);
    padding: var(--btn-padding);
    border-radius: var(--border-radius);
    font: 300 rem(14) var(--font-space);
}

.project-gallery{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: rem(50);

    @media (max-width: 1024px) {
        grid-template-columns: 1fr;
    }
}

.project-gallery img:first-child{
    grid-column: 1 / -2;
    // height: rem(600);
    height: 100%;
}

.project-gallery img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.project-gallery .project-img-carousel{
    grid-column: 1 / -1;
    height: 100%;
}

.project-img-carousel{
    display: grid;
    width: 100%;
    gap: rem(50);
    margin-bottom: rem(50);
}
.project-img-carousel img{
    width: 100%;
}