@use './util/' as *;

html{
    font-size: 100%;
    box-sizing: border-box;
    scroll-behavior: smooth;
    width: 100%;
    overflow-x: hidden;
}

*, *::before *::after {
    box-sizing: inherit;
}

:root{
    --btn-padding: 1.25rem 2.188rem;
    --border-radius: 3.125rem;
}

body{
    margin: 0;
    padding: 0 rem(50);
    font-family: var(--font-sora);
    background-color: var(--black);
    box-sizing: inherit;
    width: 100%;
    overflow-x: hidden;

    @media (max-width: 767px){
        padding: 0 rem(20);
    }
}

a, button, a * {
    cursor: pointer;
    color: var(--white);
    background: none;
    border: none;
}

// div, ul, button, a, a * {
//     transition: all 500ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
// }

a, a *, h2{
    transition: all 300ms ease;
}

figure, h2{
    margin: 0 !important;
}

h1{
    color: var(--white);
}