@use '../util/' as *;

.projects-container{
    padding: 0 rem(75);

    @media (max-width: 1300px){
        padding: 0;
    }
}

.projects {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    row-gap: rem(0);
    gap: rem(75);
    padding: 0;

    @media (max-width: 1024px){
        grid-template-columns: 1fr;
        gap: rem(50);
    }
    
}

.projects li:nth-child(odd){
    margin-top: rem(100);

    @media (max-width: 767px){
        margin-top: 0;
    }
}

.projects-grid {
    display: none;
}

.projects li{
    display: flex;
    flex-direction: column;
    height: 90%;
}

.projects li figure{
    overflow: hidden;
    height: 100%;
    border-radius: rem(15);
}

.projects li img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    scale: 1.1;
}

.projects li:hover img{
    scale: 1.15;
}

.projects li .project-data{
    margin-top: rem(25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 rem(25);
}

.project-data h2, .project-data p{
    color: var(--white);
}

.projects li .project-data .visit-arrow::before{
    content: 'Visit';
    color: var(--black);
    font: 700 rem(10) var(--font-sora);
    text-transform: uppercase;
    background-color: var(--white);
    padding: rem(3) rem(12) rem(2) rem(5);
    position: absolute;
    clip-path: polygon(0% 0%, 0 0, 0 54%, 0 100%, 0% 100%);
    margin-top: rem(17);
    margin-left: rem(-50);
    transition: all 200ms ease;
    opacity: 0;
}

.projects li:hover .project-data .visit-arrow:before{
    opacity: 1;
    clip-path: polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%);
}

.project .project-data svg{
    // border: 2px solid var(--blue);
    background-color: var(--blue);
    padding: rem(15);
    border-radius: rem(50);
    transform: rotate(-45deg);
}

.projects li .visit-arrow:hover svg {
    transform: rotate(0deg);
}

.projects li:hover h2{
    color: var(--blue);
}


